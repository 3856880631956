import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styles from "./big-image.module.scss"

const BigImage = () => {
  const data = useStaticQuery(graphql`
    query {
      big4to3: file(relativePath: { eq: "promo/big4to3.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <div className={styles.container}><Img fluid={data.big4to3.childImageSharp.fluid}/></div>
}

export default BigImage;