import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImagesGrid from "../components/images-grid"
import Download from "../components/download"
import BigImage from "../components/big-image"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <BigImage />
    <ImagesGrid />
    <h1>What is Merry Pint</h1>
    <p>
      Merry Pint is a personal beer tracker &mdash; program that helps you to
      keep a list of beers you've tried (with photos), rate them and track
      consumption data. App includes some charts to analyze consumption as well.
      Unlike bigger competitors, it doesn't require registration and doesn't
      have social features (i.e. friend list or public reviews). All data is
      stored on your device and accessible only by you. You can use backup
      feature to move data to new device
    </p>
    <h1>Download options</h1>
    <Download />
  </Layout>
)

export default IndexPage
