import React from "react"
import styles from "./download.module.scss"

const Download = () => (
  <>
    <div>
      <a
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.gendelflg.MerryPint"
        className={styles.downloadGooglePlay}
      >
        Download from Google Play
      </a>
    </div>
    <div>
      <a
        target="_blank"
        href="/merry-pint-13-signed.apk"
        className={styles.downloadApk}
      >
        Download APK
      </a>
    </div>
  </>
)

export default Download
