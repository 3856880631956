import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styles from "./images-grid.module.scss"

const ImagesGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      featuredImage: file(relativePath: { eq: "promo/featured-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      home: file(relativePath: { eq: "promo/home.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      details: file(relativePath: { eq: "promo/details.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      consumption: file(relativePath: { eq: "promo/consumption.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stats: file(relativePath: { eq: "promo/stats.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stats_monthly: file(relativePath: { eq: "promo/stats_monthly.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chart: file(relativePath: { eq: "promo/chart.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={styles.grid}>
      <div className={styles.featuredImage}>
        <Img fluid={data.featuredImage.childImageSharp.fluid} />
      </div>
      <div>
        <Img fluid={data.home.childImageSharp.fluid} />
      </div>
      <div>
        <Img fluid={data.details.childImageSharp.fluid} />
      </div>
      <div>
        <Img fluid={data.consumption.childImageSharp.fluid} />
      </div>
      <div>
        <Img fluid={data.stats.childImageSharp.fluid} />
      </div>
      <div>
        <Img fluid={data.stats_monthly.childImageSharp.fluid} />
      </div>
      <div>
        <Img fluid={data.chart.childImageSharp.fluid} />
      </div>
    </div>
  )
}

export default ImagesGrid
